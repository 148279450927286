import React, { useState } from "react";
import ReactFlow, { Background } from "reactflow";

import { Box } from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import "reactflow/dist/style.css";
import BackendApi from "../BackendApi";
import { nestedFlowData } from "./demoData";
import NestedFlowNode from "./NestedFlowNode";

const nodeTypes = {
  nestedFlow: NestedFlowNode,
};
export default ({
  mode,
  supabase,
  session,
  activeOrgId,
  activeOrgUserId,
  backendApi,
  categories,
  canvasHeight = "calc(100vh - 67px)",
}: {
  mode: string;
  supabase: SupabaseClient;
  session: Session;
  activeOrgId: string;
  activeOrgUserId: string;
  backendApi: BackendApi;
  categories: Array<any>;
  canvasHeight?: string;
}) => {
  const [nodes, setNodes] = useState(nestedFlowData.nodes);
  const [edges, setEdges] = useState(nestedFlowData.edges);

  if (!session || !session.user || !activeOrgId) {
    return <div></div>;
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box sx={{ height: canvasHeight, width: "100%" }}>
          <ReactFlow
            nodeTypes={nodeTypes}
            defaultEdgeOptions={{
              animated: true,
            }}
            nodes={nodes}
            edges={edges}
            proOptions={{ hideAttribution: true }}
            defaultViewport={{ x: 0, y: 0, zoom: 0.5 }}
          >
            <Background gap={12} size={1} />
          </ReactFlow>
        </Box>
      </Box>
    </Box>
  );
};
