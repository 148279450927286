import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";
import React from "react";
import ReactFlow, { Background, ReactFlowProvider, useStore } from "reactflow";
import "reactflow/dist/style.css";

const zoomSelector = (s) => s.transform[2] >= 0.9;

// Custom Node Component that contains a nested React Flow instance
const NestedFlowNode = ({ data }) => {
  const showContent = useStore(zoomSelector);
  return (
    <Card
      sx={{
        width: 1500 / data.level,
        height: 1000 / data.level,
        padding: 2,
        border: "1px solid #ddd",
        borderRadius: 2,
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ mb: 1 }}>
          {data.label}
        </Typography>

        {showContent ? (
          <ReactFlowProvider>
            <Box sx={{ width: "100%", height: 800 }}>
              <ReactFlow
                nodeTypes={{
                  nestedFlow: NestedFlowNode,
                }}
                defaultEdgeOptions={{
                  animated: true,
                }}
                nodes={data.childNodes}
                edges={data.childEdges}
                proOptions={{ hideAttribution: true }}
              >
                <Background gap={12} size={1} />
              </ReactFlow>
            </Box>
          </ReactFlowProvider>
        ) : (
          <Box>
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default NestedFlowNode;
