import {
  AccountTree,
  Add,
  BarChart,
  Brightness4,
  Brightness7,
  Business,
  Check,
  ChevronLeft,
  ChevronRight,
  DashboardOutlined,
  Group,
  Inbox,
  Inventory,
  Logout,
  Settings as SettingsIcon,
  Tune,
  UnfoldMore,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  styled,
  useTheme,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackendApi from "./BackendApi";
import { identify } from "./capture";
import Company from "./company/Company";
import ConfirmationDialog from "./components/ConfirmationDialog";
import { NotificationsMenu } from "./components/NotificationsMenu";
import Consultant from "./consultant/Consultant";
import Home from "./Home";
import Logo from "./Logo";
import CreateOrganizationDialog from "./organization/CreateOrganizationDialog";
import Organization from "./organization/Organization";
import Products from "./products/Products";
import Settings from "./settings/Profile";
import Canvas from "./canvas/Canvas";
import CanvasNew from "./canvas/CanvasNew";

const drawerWidth = 240;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop: any) => prop !== "open",
})<AppBarProps>(({ theme, open }: { theme: any; open: boolean }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }: { theme: any }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export default function Dashboard({
  supabase,
  mode,
  setMode,
}: {
  supabase: SupabaseClient;
  mode: string;
  setMode: (mode: string) => void;
}) {
  const theme = useTheme();
  const params = useParams();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [screen, setScreen] = useState(params.topPage || "canvas");
  const [session, setSession] = useState(null);
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [pendingOrganizations, setPendingOrganizations] = useState([]);
  const [orgCreateOpen, setOrgCreateOpen] = useState(false);
  const [activeOrgId, setActiveOrgId] = useState("");
  const [activeOrgUserId, setActiveOrgUserId] = useState("");
  const [selectOrgOpen, setSelectOrgOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorElNotications, setAnchorElNotifications] =
    useState<HTMLElement | null>(null);
  const [notifications, setNotifications] = useState([]);
  const [openConfirmInvite, setOpenConfirmInvite] = useState(false);
  const [acceptInviteOrg, setAcceptInviteOrg] = useState("");
  const [categories, setCategories] = useState([]);
  const backendApi = new BackendApi(supabase, session);

  const getCategories = async (session: Session) => {
    const categories = await backendApi.getCategoriesTree(session);
    setCategories(categories);
  };

  const handleNotificationsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorElNotifications(null);
  };

  const logout = () => {
    supabase.auth.signOut();
    navigate("/");
  };

  const setPageView = (page: string) => {
    setScreen(page);
    setOpenDrawer(false);
    navigate("/dashboard/" + page);
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      identify(session);
      setSession(session);
      getPendingOrganizations(session);
      getOrganizations(session);
      getNotifications(session);
      getCategories(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      identify(session);
      setSession(session);
      getPendingOrganizations(session);
      getOrganizations(session);
      getNotifications(session);
      getCategories(session);
    });
    return () => subscription.unsubscribe();
  }, []);

  const getNotifications = async (sess: any, active: any = null) => {
    const currActiveOrgId =
      active || activeOrgId || sess.user.user_metadata.active_organization_id;
    const notifications = await backendApi.getNotications(
      currActiveOrgId,
      sess.user.id
    );
    setNotifications(notifications);
  };

  const getOrganizations = async (sess: any, active: any = null) => {
    const currActiveOrgId =
      active || activeOrgId || sess.user.user_metadata.active_organization_id;
    backendApi.getOrganizations(sess).then((organizations) => {
      setOrganizations(organizations);
      if (currActiveOrgId && organizations.length !== 0) {
        setActiveOrgId(currActiveOrgId);
        const selectedOrg = organizations.find(
          (x) => x.organization_id === currActiveOrgId
        );
        setActiveOrgUserId(selectedOrg.id);
      } else if (organizations.length !== 0) {
        setActiveOrgId(organizations[0].organization_id);
        setActiveOrgUserId(organizations[0].id);
      } else {
        setOrgCreateOpen(true);
      }
    });
  };

  const getPendingOrganizations = async (sess: any) => {
    const pending = await backendApi.getPendingOrganizations(sess.user.email);
    setPendingOrganizations(pending);
    pending.forEach((org: any) => {
      if (!org.accepted) {
        setOpenConfirmInvite(true);
        setAcceptInviteOrg(org.organizations.name);
      }
    });
  };

  const getActiveOrgName = () => {
    if (
      activeOrgId &&
      organizations.length !== 0 &&
      organizations.find((x) => x.organization_id === activeOrgId)
    ) {
      return organizations.find((x) => x.organization_id === activeOrgId)
        .organizations.name;
    }
    return "None";
  };

  const setActiveOrganizationId = (orgId) => {
    setActiveOrgId(orgId);
    supabase.auth.updateUser({
      data: {
        active_organization_id: orgId,
      },
    });
    setSelectOrgOpen(false);
    setAnchorEl(null);
    getOrganizations(session, orgId);
  };

  const createNewOrganization = () => {
    setSelectOrgOpen(false);
    setAnchorEl(null);
    setOrgCreateOpen(true);
  };

  const confirmOrganization = async () => {
    await backendApi.joinOrganizations(
      pendingOrganizations
        .map((x) => {
          return {
            id: x.id,
            organization_id: x.organization_id,
            role: x.role,
            accepted: x.accepted,
            user_id: session.user.id,
            name: session.user.user_metadata.name,
            photo_url: session.user.user_metadata.avatar_url,
          };
        })
        .filter((x) => !x.accepted)
    );
  };

  return (
    <Box sx={{ flexGrow: 1, display: "flex" }}>
      <ConfirmationDialog
        open={openConfirmInvite}
        onClose={() => setOpenConfirmInvite(false)}
        onConfirm={confirmOrganization}
        title={`Join Organization?`}
        message={`You have been invited to join organization ${acceptInviteOrg}. Do you want to accept?`}
        confirmText={"Accept"}
        cancelText={"Reject"}
      />
      <CreateOrganizationDialog
        supabase={supabase}
        session={session}
        open={orgCreateOpen}
        onClose={() => {
          setOrgCreateOpen(activeOrgId === null);
          getOrganizations(session);
        }}
        onSubmit={(orgId) => {
          setActiveOrganizationId(orgId);
          setOrgCreateOpen(false);
        }}
      />
      <AppBar
        position="fixed"
        elevation={0}
        open={openDrawer}
        sx={{
          backgroundImage: "none",
          borderBottom:
            theme.palette.mode === "dark"
              ? "1px solid rgba(255, 255, 255, 0.12)"
              : "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpenDrawer(true)}
            edge="start"
            sx={{ mr: 2, ...(openDrawer && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Logo mode={mode} />
          <div style={{ flexGrow: 1 }}></div>
          <IconButton>
            <BarChart />
          </IconButton>
          <IconButton onClick={handleNotificationsClick}>
            <Badge badgeContent={notifications.length} color="secondary">
              <Inbox />
            </Badge>
          </IconButton>
          <NotificationsMenu
            notifications={notifications}
            open={Boolean(anchorElNotications)}
            anchorEl={anchorElNotications}
            onClose={handleNotificationsClose}
          />
          <IconButton
            sx={{ ml: 1, mr: 0.5 }}
            onClick={() => setMode(mode === "light" ? "dark" : "light")}
          >
            {mode === "dark" ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
          <IconButton onClick={() => setPageView("profile")}>
            <Avatar
              alt={
                session && session.user.user_metadata
                  ? session.user.user_metadata.name
                  : ""
              }
              src={
                session && session.user.user_metadata
                  ? session.user.user_metadata.avatar_url
                  : ""
              }
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={openDrawer}
      >
        <DrawerHeader>
          <IconButton onClick={() => setOpenDrawer(false)}>
            {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={(e: any) => {
                setAnchorEl(e.currentTarget);
                setSelectOrgOpen(true);
              }}
            >
              <ListItemText primary={getActiveOrgName()} />
              <UnfoldMore />
            </ListItemButton>
            <Menu
              open={selectOrgOpen}
              anchorEl={anchorEl}
              onClose={() => setSelectOrgOpen(false)}
            >
              {organizations.map((org) => (
                <MenuItem
                  key={org.organization_id}
                  onClick={() => setActiveOrganizationId(org.organization_id)}
                  disabled={org.organization_id === activeOrgId}
                  selected={org.organization_id === activeOrgId}
                >
                  {org.organizations.name}
                  <Box sx={{ flexGrow: 1 }}></Box>
                  {org.organization_id === activeOrgId && <Check />}
                </MenuItem>
              ))}
              <Divider />
              <MenuItem onClick={createNewOrganization}>
                <ListItemIcon>
                  <Add />
                </ListItemIcon>
                <ListItemText>Create New Organization</ListItemText>
              </MenuItem>
            </Menu>
          </ListItem>
          {/* <ListItemButton
            selected={screen === "dashboard"}
            disabled={screen === "dashboard"}
            onClick={() => setPageView("dashboard")}
          >
            <ListItemIcon>
              <DashboardOutlined />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItemButton> */}
          <ListItemButton
            selected={screen === "canvas"}
            disabled={screen === "canvas"}
            onClick={() => setPageView("canvas")}
          >
            <ListItemIcon>
              <AccountTree />
            </ListItemIcon>
            <ListItemText primary={"Canvas"} />
          </ListItemButton>
          {/* <ListItemButton
            selected={screen === "company"}
            disabled={screen === "company"}
            onClick={() => setPageView("company")}
          >
            <ListItemIcon>
              <Business />
            </ListItemIcon>
            <ListItemText primary={"Company"} />
          </ListItemButton>
          <ListItemButton
            selected={screen === "consultant"}
            disabled={screen === "consultant"}
            onClick={() => setPageView("consultant")}
          >
            <ListItemIcon>
              <Group />
            </ListItemIcon>
            <ListItemText primary={"Consultant"} />
          </ListItemButton>
          <ListItemButton
            selected={screen === "products"}
            disabled={screen === "products"}
            onClick={() => setPageView("products")}
          >
            <ListItemIcon>
              <Inventory />
            </ListItemIcon>
            <ListItemText primary={"Products"} />
          </ListItemButton> */}
          <ListItemButton
            selected={screen === "organization"}
            disabled={screen === "organization"}
            onClick={() => setPageView("organization")}
          >
            <ListItemIcon>
              <Tune />
            </ListItemIcon>
            <ListItemText primary={"Organization"} />
          </ListItemButton>
        </List>
        <Divider />
        <List>
          <ListItemButton
            selected={screen === "profile"}
            disabled={screen === "profile"}
            onClick={() => setPageView("profile")}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={"Profile"} />
          </ListItemButton>
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </List>
      </Drawer>
      <Main open={openDrawer}>
        <DrawerHeader />
        {screen === "canvas" && (
          <CanvasNew
            mode={mode}
            supabase={supabase}
            session={session}
            activeOrgId={activeOrgId}
            activeOrgUserId={activeOrgUserId}
            backendApi={backendApi}
            categories={categories}
          />
        )}
        {screen === "dashboard" && <Home />}
        {screen === "products" && (
          <Products
            mode={mode}
            supabase={supabase}
            session={session}
            activeOrgId={activeOrgId}
            backendApi={backendApi}
          />
        )}
        {screen === "company" && (
          <Company
            mode={mode}
            supabase={supabase}
            session={session}
            activeOrgId={activeOrgId}
            activeOrgUserId={activeOrgUserId}
            backendApi={backendApi}
          />
        )}
        {screen === "organization" && (
          <Organization
            mode={mode}
            supabase={supabase}
            session={session}
            activeOrgId={activeOrgId}
            backendApi={backendApi}
          />
        )}
        {screen === "profile" && (
          <Settings
            mode={mode}
            supabase={supabase}
            session={session}
            activeOrgId={activeOrgId}
            backendApi={backendApi}
          />
        )}
        {screen === "consultant" && (
          <Consultant
            mode={mode}
            supabase={supabase}
            session={session}
            activeOrgId={activeOrgId}
            backendApi={backendApi}
          />
        )}
      </Main>
    </Box>
  );
}
