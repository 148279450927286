import { Box, Typography } from "@mui/material";
import React from "react";

export default ({ mode }: { mode: string }) => {
  return (
    <Box sx={{ pt: 1, pb: 1 }}>
      <a>
        <Typography
          variant="h5"
          sx={{ color: mode === "dark" ? "#fff" : "#000000" }}
        >
          haibar.
          <span
            style={{
              textDecoration: "underline",
              textDecorationThickness: "2px",
              textUnderlineOffset: "4px",
              fontFamily: '"Inter", sans-serif',
            }}
          >
            ai
          </span>
        </Typography>
      </a>
    </Box>
  );
};
