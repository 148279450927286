// Demo data for the React Flow graph

import NestedFlowNode from "./NestedFlowNode";

// Level 4: People assigned to projects
const peopleLevel = {
  "salesforce-pipeline": [
    {
      id: "person-1",
      position: { x: 100, y: 100 },
      data: { level: 4, label: "John Doe (Engineer)" },
      type: "default",
    },
    {
      id: "person-2",
      position: { x: 250, y: 100 },
      data: { level: 4, label: "Sarah Johnson (PM)" },
      type: "default",
    },
    {
      id: "person-3",
      position: { x: 400, y: 100 },
      data: { level: 4, label: "Mike Zhang (Designer)" },
      type: "default",
    },
  ],
  "netsuite-inventory": [
    {
      id: "person-4",
      position: { x: 100, y: 100 },
      data: { level: 4, label: "Priya Patel (Data Analyst)" },
      type: "default",
    },
    {
      id: "person-5",
      position: { x: 300, y: 100 },
      data: { level: 4, label: "Alex Thompson (ERP Specialist)" },
      type: "default",
    },
  ],
  "hubspot-marketing": [
    {
      id: "person-6",
      position: { x: 100, y: 100 },
      data: { level: 4, label: "Lisa Wong (Marketing Lead)" },
      type: "default",
    },
    {
      id: "person-7",
      position: { x: 300, y: 100 },
      data: { level: 4, label: "David Brown (Content Creator)" },
      type: "default",
    },
  ],
  "asana-campaign": [
    {
      id: "person-8",
      position: { x: 100, y: 100 },
      data: { level: 4, label: "James Wilson (Creative Director)" },
      type: "default",
    },
    {
      id: "person-9",
      position: { x: 300, y: 150 },
      data: { level: 4, label: "Emily Garcia (Campaign Manager)" },
      type: "default",
    },
    {
      id: "person-10",
      position: { x: 200, y: 200 },
      data: {
        level: 4,
        label: "Robert Lee (Social Media Expert)",
      },
      type: "default",
    },
  ],
};

// Level 3: Projects within Applications
const projectsLevel = {
  "salesforce-sales-cloud": [
    {
      id: "project-1",
      position: { x: 100, y: 100 },
      data: {
        level: 3,
        label: "Q2 Pipeline Revamp",
        childNodes: peopleLevel["salesforce-pipeline"],
        childEdges: [
          { id: "e-p1-1", source: "person-1", target: "person-2" },
          { id: "e-p1-2", source: "person-2", target: "person-3" },
        ],
      },
      type: "nestedFlow",
    },
    {
      id: "project-2",
      position: { x: 100, y: 450 },
      data: { level: 3, label: "Customer Success Initiative" },
      type: "default",
    },
  ],
  "netsuite-erp": [
    {
      id: "project-3",
      position: { x: 100, y: 100 },
      data: {
        level: 3,
        label: "Inventory Cleanup Initiative",
        childNodes: peopleLevel["netsuite-inventory"],
        childEdges: [{ id: "e-p2-1", source: "person-4", target: "person-5" }],
      },
      type: "nestedFlow",
    },
  ],
  "hubspot-marketing": [
    {
      id: "project-4",
      position: { x: 100, y: 100 },
      data: {
        level: 3,
        label: "Lead Generation Overhaul",
        childNodes: peopleLevel["hubspot-marketing"],
        childEdges: [{ id: "e-p3-1", source: "person-6", target: "person-7" }],
      },
      type: "nestedFlow",
    },
  ],
  "asana-workspace": [
    {
      id: "project-5",
      position: { x: 100, y: 100 },
      data: {
        level: 3,
        label: "Campaign Launch",
        childNodes: peopleLevel["asana-campaign"],
        childEdges: [
          { id: "e-p4-1", source: "person-8", target: "person-9" },
          { id: "e-p4-2", source: "person-9", target: "person-10" },
        ],
      },
      type: "nestedFlow",
    },
  ],
};

// Level 2: Applications within Products
const applicationsLevel = {
  salesforce: [
    {
      id: "app-1",
      position: { x: 100, y: 100 },
      data: {
        level: 2,
        label: "Salesforce Sales Cloud",
        childNodes: projectsLevel["salesforce-sales-cloud"],
        childEdges: [
          { id: "e-a1-1", source: "project-1", target: "project-2" },
        ],
      },
      type: "nestedFlow",
    },
    {
      id: "app-2",
      position: { x: 100, y: 450 },
      data: { level: 2, label: "Salesforce Service Cloud" },
      type: "default",
    },
  ],
  netsuite: [
    {
      id: "app-3",
      position: { x: 100, y: 100 },
      data: {
        level: 2,
        label: "NetSuite ERP Module",
        childNodes: projectsLevel["netsuite-erp"],
        childEdges: [],
      },
      type: "nestedFlow",
    },
    {
      id: "app-4",
      position: { x: 100, y: 450 },
      data: { level: 2, label: "NetSuite CRM Module" },
      type: "default",
    },
  ],
  hubspot: [
    {
      id: "app-5",
      position: { x: 100, y: 100 },
      data: {
        level: 2,
        label: "HubSpot Marketing Hub",
        childNodes: projectsLevel["hubspot-marketing"],
        childEdges: [],
      },
      type: "nestedFlow",
    },
  ],
  asana: [
    {
      id: "app-6",
      position: { x: 100, y: 100 },
      data: {
        level: 2,
        label: "Asana Marketing Workspace",
        childNodes: projectsLevel["asana-workspace"],
        childEdges: [],
      },
      type: "nestedFlow",
    },
  ],
};

// Level 1: Software Products (Top Level)
const initialNodes = [
  {
    id: "product-1",
    position: { x: 100, y: 100 },
    data: {
      level: 1,
      label: "Salesforce",
      childNodes: applicationsLevel["salesforce"],
      childEdges: [{ id: "e-p1-1", source: "app-1", target: "app-2" }],
    },
    type: "nestedFlow",
  },
  {
    id: "product-2",
    position: { x: 1800, y: 100 },
    data: {
      level: 1,
      label: "NetSuite",
      childNodes: applicationsLevel["netsuite"],
      childEdges: [{ id: "e-p2-1", source: "app-3", target: "app-4" }],
    },
    type: "nestedFlow",
  },
  {
    id: "product-3",
    position: { x: 1800, y: 1500 },
    data: {
      level: 1,
      label: "HubSpot",
      childNodes: applicationsLevel["hubspot"],
      childEdges: [],
    },
    type: "nestedFlow",
  },
  {
    id: "product-4",
    position: { x: 100, y: 1500 },
    data: {
      level: 1,
      label: "Asana",
      childNodes: applicationsLevel["asana"],
      childEdges: [],
    },
    type: "nestedFlow",
  },
];

const initialEdges = [
  { id: "e1-2", source: "product-1", target: "product-2" },
  { id: "e1-3", source: "product-1", target: "product-3" },
  { id: "e3-4", source: "product-3", target: "product-4" },
];

export const nestedFlowData = {
  nodes: initialNodes,
  edges: initialEdges,
};

const nodeTypes = {
  nestedFlow: NestedFlowNode,
};
